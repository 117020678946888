<template>
  <vx-card :title="title">
    <vs-tabs :value="active">
      <vs-tab label="Category">
        <Category></Category>
      </vs-tab>
      <vs-tab label="Classifications">
        <Classifications></Classifications>
      </vs-tab>
      <vs-tab label="Division">
        <Division></Division>
      </vs-tab>
      <vs-tab label="Handling Unit">
        <HandlingUnit></HandlingUnit>
      </vs-tab>
      <vs-tab label="Item Material">
        <ItemMaterial></ItemMaterial>
      </vs-tab>
      <vs-tab label="Principal">
        <Principal></Principal>
      </vs-tab>
      <vs-tab label="Supplier">
        <Supplier></Supplier>
      </vs-tab>
      <vs-tab label="Supplier Account">
        <SupplierAccount></SupplierAccount>
      </vs-tab>
      <vs-tab label="Unit of Measure">
        <UnitOfMeasure></UnitOfMeasure>
      </vs-tab>
      <vs-tab label="Taxes">
        <Taxes></Taxes>
      </vs-tab>
      <vs-tab label="Sub Taxes">
        <SubTaxes></SubTaxes>
      </vs-tab>
<!--      <vs-tab label="Begbal">-->
<!--        <Begbal></Begbal>-->
<!--      </vs-tab>-->
      <vs-tab label="SKU Accounting">
        <SkuAccounting></SkuAccounting>
      </vs-tab>
      <vs-tab label="SKU Handling Unit">
        <SkuHandlingUnit></SkuHandlingUnit>
      </vs-tab>
       <vs-tab label="SKU">
         <Sku></Sku>
       </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import Category from "./category/index.vue";
// import Begbal from "./begbal/index.vue";
import Classifications from "./classification/index.vue";
import Division from "./division/index.vue";
import HandlingUnit from "./handling-unit/index.vue";
import ItemMaterial from "./item-material/index.vue";
import Principal from "./principal/index.vue";
import Supplier from "./supplier/index.vue";
import SupplierAccount from "./supplier-account/index.vue";
import UnitOfMeasure from "./unit-of-measure/index.vue";
import Taxes from "./taxes/index.vue";
import SubTaxes from "./sub-taxes/index.vue";
import SkuAccounting from "./sku-accounting/index.vue";
import SkuHandlingUnit from "./sku-handling-unit/index.vue";
import Sku from "./sku/index.vue";

export default {
  props: {
    active: {
      type: Number,
    },
  },
  components: {
    Category,
    Classifications,
    Division,
    HandlingUnit,
    ItemMaterial,
    Principal,
    Supplier,
    SupplierAccount,
    UnitOfMeasure,
    Taxes,
    SubTaxes,
    // Begbal,
    SkuAccounting,
    SkuHandlingUnit,
    Sku,
  },
  data() {
    return {
      title: "SKU Import",
    };
  },
};
</script>
